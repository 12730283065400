<template>
  <div>
    <div v-if="!startPlace && !endPlace" @click="toggleDir">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0946.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <div class="text-center">
        <h6 class="q-my-none q-mr-sm">
          {{ $t('Please scan location') }}
        </h6>

        <q-badge
            :label="dir === 'to' ? $t('TO') : $t('FROM')"
            color="amber"
            class="text-h6"
        />

        <p class="q-mb-none q-mt-sm">
          {{ $t('tap on the screen to change direction') }}
        </p>
      </div>
    </div>

    <div v-else-if="(!startPlace && endPlace) || (startPlace && !endPlace)" @click="toggleDir">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0946.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <div class="text-center">
        <h6 class="q-my-none q-mr-sm">
          {{ $t('Please scan location') }}
        </h6>

        <q-badge
            :label="dir === 'to' ? $t('FROM') : $t('TO')"
            color="amber"
            class="text-h6"
        />

        <p class="q-mb-none q-mt-sm">
          {{ $t('tap on the screen to change direction') }}
        </p>
      </div>
    </div>

    <div v-else-if="!item">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0943.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <div class="row items-center justify-center">
        <h6 class="q-my-none q-mr-sm">
          {{ $t('Please scan') }}
        </h6>

        <q-badge
            :label="$t('Items')"
            color="amber"
            class="text-h6"
        />
      </div>
    </div>

    <div
        v-if="item"
        :key="`${item.id}:${item.sku}`"
    >
      <product-section :data="item"/>

      <quantity-section
          :data="quantity"
          :options="quantityOptions"
          @click="handleEvent"
      />

      <counter-section :data="counter" @click="handleEvent"/>
    </div>

    <div class="border-top q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
      <base-place-section
          :data="endPlace"
          :options="endPlaceOptions"
          class="q-mb-sm"
      />

      <base-place-section
          :data="startPlace"
          :options="startPlaceOptions"
      />
    </div>

    <offers-modal ref="offersModal"/>

    <portal v-if="item" to="additional-tiles">
      <div
          class="text-center rounded row items-center justify-center"
          style="background: rgba(0, 0, 0, 0.9); min-height: 66px;"
          @click="handleFinish"
      >
        <div class="q-px-sm">
          <q-icon name="check" size="2rem"/>

          <div class="text-caption">
            {{ $t('Done') }}
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

// Components
import OffersModal from '../../components/modals/OffersModal.vue'

export default {
  name: 'MoveItems',
  components: {
    OffersModal
  },
  props: {
    barcode: {
      type: [Object, null],
      default() {
        return null
      }
    }
  },
  data() {
    return {
      dir: 'to',
      item: null,
      startPlace: null,
      endPlace: null,
      startPlaceItems: [],
      endPlaceItems: [],
      error: '',
      amount: 1
    }
  },
  computed: {
    ...mapGetters([
      'findStoragePlaceEvent'
    ]),
    quantityOptions() {
      return {
        maxDescription: 'tap to add all',
        max: 'Available',
        current: 'Scanned'
      }
    },
    quantity() {
      return {
        max: this.item.max,
        current: this.item.count
      }
    },
    counter() {
      return {
        max: this.item.max > 250
            ? 250
            : this.item.max
      }
    },
    startPlaceOptions() {
      if (this.dir === 'to') {
        return {
          label: this.$t('FROM'),
          defaultColor: !this.endPlace
              ? 'grey-7'
              : 'amber',
          selectedColor: 'positive'
        }
      }

      return {
        label: this.$t('FROM'),
        defaultColor: 'amber',
        selectedColor: 'positive'
      }
    },
    endPlaceOptions() {
      if (this.dir === 'from') {
        return {
          label: this.$t('TO'),
          defaultColor: !this.startPlace
              ? 'grey-7'
              : 'amber',
          selectedColor: 'positive'
        }
      }

      return {
        label: this.$t('TO'),
        defaultColor: 'amber',
        selectedColor: 'positive'
      }
    },
  },
  watch: {
    barcode(value) {
      this.handleBarcode(value)
    }
  },
  unmounted() {
    this.deleteAllHelpers()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'deleteAllHelpers'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    toggleDir() {
      this.dir = this.dir === 'to'
          ? 'from'
          : 'to'
    },
    handleFinish() {
      this.$emit('finish')
    },
    handleEvent(value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          this.amount = Number(data.value || 0)
          return this.handleBarcode(this.barcode)
        },
        'counter.focus': () => {
          this.$emit('focus', true)
        },
        'counter.focusOut': () => {
          this.$emit('focusOut', true)
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    handleErrorClose() {
      this.error = ''
    },
    setDirTo(place) {
      let oldPlace = null

      if (!this.endPlace) {
        this.endPlace = place
        this.endPlaceItems = []
      } else {
        if (this.startPlace) {
          oldPlace = {...this.startPlace}
        }

        this.startPlace = place
        this.startPlaceItems = []
      }

      return {oldPlace, place}
    },
    setDirFrom(place) {
      let oldPlace = null

      if (!this.startPlace) {
        this.startPlace = place
        this.startPlaceItems = []
      } else {
        if (this.endPlace) {
          oldPlace = {...this.endPlace}
        }

        this.endPlace = place
        this.endPlaceItems = []
      }

      return {oldPlace, place}
    },
    setPlace(place) {
      let oldPlace = null

      if (this.dir === 'to') {
        const result = this.setDirTo(place)
        oldPlace = result.oldPlace
      } else {
        const result = this.setDirFrom(place)
        oldPlace = result.oldPlace
      }

      if (oldPlace) {
        this.$channels.user.publish('closePlace', oldPlace.id)
      }

      this.$channels.user.publish('openPlace', place.id, place)
      return Promise.resolve(place)
    },
    scanPlace(barcode) {
      if (this.findStoragePlaceEvent(barcode.value)) {
        return Promise.reject(new Error('Place is already used from someone!'))
      }

      return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            return this.setPlace(place)
          })
    },
    getItemByBarcode(arr, barcode) {
      const items = arr.filter(({sku}) => sku === barcode.raw)

      if (items.length > 1) {
        return this.$refs.offersModal.show(items)
      }

      return Promise.resolve(items[0] || null)
    },
    async loadItemFromStartPlace(barcode) {
      const item = await this.getItemByBarcode(this.startPlaceItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          {type: 'eq', field: 'place', value: this.startPlace.id}
        ],
        group: [
          {field: 'productOffer', alias: 'i'}
        ]
      }

      query.filter.push({type: 'eq', field: 'sku', value: barcode.raw});

      return this.$service.storageItemEntity.getAll(query, this.startPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({items}) => {
            if (items.length > 1) {
              return this.$refs.offersModal.show(items)
            }

            return items[0]
          })
          .then(item => {
            if (!item) {
              return null
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw
            }

            this.startPlaceItems.push(updatedItem)

            return updatedItem
          })
    },
    async loadItemFromEndPlace(barcode, product) {
      const item = await this.getItemByBarcode(this.endPlaceItems, barcode)

      if (item) {
        return Promise.resolve(item)
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          {type: 'eq', field: 'place', value: this.endPlace.id}
        ],
        group: [
          {field: 'productOffer', alias: 'i'}
        ]
      }

      if (product) {
        query.filter.push({type: 'eq', field: 'productOffer', value: product.id})
      }

      query.filter.push({type: 'eq', field: 'sku', value: barcode.raw})

      return this.$service.storageItemEntity.getAll(query, this.endPlace.id)
          .then(data => {
            return {
              ...data,
              items: this.$utils.formatGroupedItemsByOffer(data.items)
            }
          })
          .then(({items}) => {
            const item = items[0]

            if (!item) {
              return null
            }

            const updatedItem = {
              ...item,
              sku: barcode.raw
            }

            this.endPlaceItems.push(updatedItem)

            return updatedItem
          })
    },
    getProduct(barcode) {
      let startPlaceItem = null

      return this.loadItemFromStartPlace(barcode)
          .then(item => {
            if (!item) {
              return Promise.reject(new Error(`This product should not be here! Please ask your supervisor for the further actions about the product.`))
            }

            startPlaceItem = item

            return this.loadItemFromEndPlace(barcode, startPlaceItem)
          })
          .then(item => {
            return {
              ...(item || {...startPlaceItem, count: 0}),
              max: startPlaceItem.count
            }
          })
    },
    moveItem(barcode) {
      return this.getProduct(barcode)
          .then(item => {
            if (this.amount > item.max) {
              return Promise.reject(new Error(`Max count is ${item.max}!`))
            }

            const query = {
              per_page: this.amount,
              filter: [
                {type: 'eq', field: 'place', value: this.startPlace.id}
              ]
            }

            query.filter.push({type: 'eq', field: 'sku', value: barcode.raw})

            return Promise.all([item, this.$service.storageItemEntity.patch([{place: this.endPlace.id}], query, 'application/json+offers', this.endPlace.id)])
          })
          .then(([item]) => {
            let isExist = false

            this.endPlaceItems = this.endPlaceItems.reduce((acc, i) => {
              if (item.id === i.id) {
                isExist = true

                const updatedItem = {
                  ...i,
                  count: i.count + this.amount,
                  max: item.max - this.amount
                }

                this.item = updatedItem
                acc.push(updatedItem)
              } else {
                acc.push(i)
              }

              return acc
            }, [])

            if (!isExist) {
              this.item = {...item, max: item.max - this.amount, count: this.amount}
              this.endPlaceItems.push(this.item)
            }

            this.startPlaceItems = this.startPlaceItems.reduce((acc, i) => {
              if (i.id === item.id) {
                const updatedItem = {...i, count: i.count - this.amount}

                if (updatedItem.count > 0) {
                  acc.push(updatedItem)
                }
              } else {
                acc.push(i)
              }

              return acc
            }, [])

            this.amount = 1
          })
    },
    getPlaceError(barcode) {
      if (this.startPlace && `${this.startPlace.id}` === `${barcode.value}`) {
        return `Place with barcode ${barcode.raw} is already scanned`
      }

      if (this.endPlace && `${this.endPlace.id}` === `${barcode.value}`) {
        return `Place with barcode ${barcode.raw} is already scanned`
      }

      return ''
    },
    getItemError() {
      if (!this.startPlace || !this.endPlace) {
        return 'You must scan place!'
      }

      return ''
    },
    getError(barcode) {
      return barcode.raw.includes('S/P/')
          ? this.getPlaceError(barcode)
          : this.getItemError(barcode)
    },
    handleBarcode(barcode) {
      const error = this.getError(barcode)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      this.slide = 'scan'

      return Promise.resolve(barcode.raw.includes('S/P/'))
          .then(isPlace => {
            return isPlace
                ? this.scanPlace(barcode)
                : this.moveItem(barcode)
          })
          .catch(error => {
            this.deleteAllHelpers()
            this.addHelper(error.message)
          })
    }
  }
}
</script>
